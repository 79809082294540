import React from "react";
import processIcon from "../assets/Process.svg";

export const ReStakingPopup = ({ onProceed, onCancel, type, unStakeAmount }) => {
  return (
    <div id="myModal" className="custom_modal address_modal">
      <div className="confirm_modal rounded-3xl background_popup !z-40 pb-4">
        <div className="custom_modal-header">
          <div className="modal_address">
            <div className="confirm_modal_head font-syne font-bold text-4xl">
              <img style={{ width: "6rem" }} src={processIcon} alt="" />
              <div className="modal_head_gradient_text">Please Note!</div>
            </div>

            <div className="address_modal_description">
              {type === "stake" ? (
                <>
                  Your rewards will be reset to 0 if you stake more from same wallet. <br /> Do you
                  wish to proceed?
                </>
              ) : (
                `You will receive ${unStakeAmount} tokens on unstaking`
              )}
            </div>

            <div className="flex justify-around gap-3">
              <div
                className="connect_wallet_button border-1 rounded-full flex justify-center items-center border-[#FF4E4E]"
                onClick={() => onCancel()}
              >
                <button className="initiate_button text-red-500 font-syne text-xl ">Cancel</button>
              </div>
              <div
                className="connect_wallet_button bg-gradient-to-r from-purple-500 to-blue-600 rounded-3xl py-1 cursor-pointer"
                style={{ width: "100%" }}
                onClick={() => onProceed()}
              >
                <button className="initiate_button">
                  <span className="text-white font-syne text-xl">Proceed</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
