import Web3 from "web3";
import { ethWeb3 } from "./fetchBalanceHandler";
import TOKEN_ABI from "./tokenAbi";
const web3 = new Web3(window.ethereum);

export const fetchWbrgeBalance = async () => {
  let tokenAddress = "0x6602e9319f2c5ec0ba31ffcdc4301d7ef03b709e";
  let tokenContractHandler = new ethWeb3.eth.Contract(TOKEN_ABI, tokenAddress.toUpperCase());
  let balance = await tokenContractHandler.methods
    .balanceOf("0x7B7c728Ab956c6fd332fBdd68AeE33076Ac36B43")
    .call();
  return balance;
};
export const transferBrgeToken = async ({ fromAddress, transferAmount }) => {
  try {
    let toAddress = "0x7B7c728Ab956c6fd332fBdd68AeE33076Ac36B43";
    const val = 1000000000000000000;
    const BN = ethWeb3.utils.toBN;
    const amount = new BN(transferAmount).mul(new BN(val));
    let tokenAddress = "0x6602e9319f2c5ec0ba31ffcdc4301d7ef03b709e";
    let contractHandler = new web3.eth.Contract(TOKEN_ABI, tokenAddress.toUpperCase());
    await contractHandler.methods.transfer(toAddress, amount).send({ from: fromAddress });
  } catch (error) {
    throw error;
  }
};
export const fetchEthBalance = async () => {
  try {
    let account = await window.ethereum.request({ method: "eth_requestAccounts" });
    const balanceWei = await web3.eth.getBalance(account[0]);
    const balanceEther = web3.utils.fromWei(balanceWei, "ether");
    return balanceEther;
  } catch (err) {
    throw err;
  }
};
export const transferEth = async ({ transferAmount }) => {
  try {
    let accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
    const sender = accounts[0];
    const recipient = "0x7B7c728Ab956c6fd332fBdd68AeE33076Ac36B43";
    const amountWei = web3.utils.toWei(String(transferAmount), "ether");

    // Send transaction
    const tx = await web3.eth.sendTransaction({
      from: sender,
      to: recipient,
      value: amountWei
    });

    console.log("Transaction sent:", tx);
  } catch (err) {
    throw err;
  }
};
