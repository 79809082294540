import React, { useState } from "react";
import Dot from "../assets/dot.png";
import { useNavigate } from "react-router-dom";

const FeatureItem = ({ image, icon, title }) => {
  const navigate = useNavigate();
  return (
    <div
      className="project-feature-item  sm:h-[300px] h-[500px] bg-no-repeat bg-cover flex flex-col justify-between fi-padding rounded-3xl mb-5 "
      style={{ backgroundImage: `radial-gradient(transparent, black), url(${image})` }}>
      <div className="flex justify-end">
        <button className="landing-page-hero-content--button--alt py-2 breathing-effect font-semibold flex items-center gap-1  text-[15px] text-white">
          Sold Out
          {/* <img src={Dot} alt="" height="14px" width="14px" className="rounded-full " /> */}
        </button>
        {/* <button className="landing-page-hero-content--button--alt font-semibold py-2 px-4 ">
          Refundable
        </button> */}
      </div>
      <div className="flex items-center justify-between sm:flex-col sm:justify-center sm:gap-2">
        <div className="flex items-center gap-2">
          <img src={icon} className="rounded-full sm:h-[50px] h-[100px]" alt="" />
          <h3 className="sm:text-lg text-5xl font-bold text-gray-200">{title}</h3>
        </div>
        <button
          className="landing-page-hero-content--button font-semibold sm:w-full"
          onClick={() => navigate("/projects/SKO")}>
          Participate
        </button>
      </div>
    </div>
  );
};

const Projects = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <div>
      <div className="container min-h-screen mx-auto py-20">
        {/*<div className="flex text-white p-2 gap-2 items-center justify-center mb-4">*/}
        {/*  <div className="project-tabs-container">*/}
        {/*    <button*/}
        {/*      className={`project-tabs-button ${*/}
        {/*        selectedTab === 0 ? "project-tabs-button--active" : ""*/}
        {/*      }`}*/}
        {/*      onClick={() => setSelectedTab(0)}>*/}
        {/*      Whitelist*/}
        {/*    </button>*/}
        {/*    <button*/}
        {/*      className={`project-tabs-button ${*/}
        {/*        selectedTab === 1 ? "project-tabs-button--active" : ""*/}
        {/*      }`}*/}
        {/*      onClick={() => setSelectedTab(1)}>*/}
        {/*      Non-Whitelist*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div>
          <FeatureItem
            image="https://pbs.twimg.com/profile_banners/1421872564155174914/1711141848"
            icon="https://pbs.twimg.com/profile_images/1746193555087822848/TDGF7LkW_400x400.jpg"
            title="Sugar Kingdom Odyssey"
          />
          {/*<FeatureItem*/}
          {/*  image="https://static.vecteezy.com/system/resources/thumbnails/001/820/662/small/business-banner-template-simple-geometric-style-vector.jpg"*/}
          {/*  icon="https://ik.imagekit.io/l3jhuoqd2/Companies/project_logo_dappad1708520083733.png"*/}
          {/*/>*/}
          {/*<FeatureItem*/}
          {/*  image="https://static.vecteezy.com/system/resources/thumbnails/001/820/662/small/business-banner-template-simple-geometric-style-vector.jpg"*/}
          {/*  icon="https://ik.imagekit.io/l3jhuoqd2/Companies/project_logo_dappad1708520083733.png"*/}
          {/*/>*/}
        </div>
        {/*<div className="no-project-container ">*/}
        {/*  <div>*/}
        {/*    <img src={NoProject} alt="" />*/}
        {/*  </div>*/}
        {/*  <h1 className="no-project-heading">No projects yet</h1>*/}
        {/*  <p className="text-white text-[16px] font-grostek md:text-center">*/}
        {/*    Stay tuned, the projects will be displayed here soon!*/}
        {/*  </p>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default Projects;
