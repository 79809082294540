import React from "react";

const ProgressBar = ({
  total,
  progressed,
  progressLabel,
  className,
  barContainerClass,
  progressLineClass
}) => {
  const progressPercentage = Math.floor((progressed / total) * 100);

  return (
    <div className={`flex flex-col w-full gap-1 ${className}`}>
      <div
        className={`w-full bg-[#3e2d8460] border-1 border-[#3e2d84] rounded-full ${barContainerClass} `}>
        <div
          className={`h-4 bg-[#A689FF] rounded-full ${progressLineClass}`}
          style={{ width: `${progressPercentage}%` }}
        />
      </div>
      <div className="flex items-center justify-between">
        <p className="font-semibold md:text-xs">30k {progressLabel}</p>
        <p className="font-semibold md:text-xs">30k {progressLabel}</p>
      </div>
    </div>
  );
};

export default ProgressBar;
