import React, { useState, useEffect } from "react";
import ConnectMetaMaskWallet from "./Navbar/ConnectMetaMaskWallet";
import { ThreeDots } from "react-loader-spinner";
import Border from "../assets/border.png";
import EthereumIcon from "../assets/ethereum.png";
import { toast } from "react-toastify";
import {
  allowanceHandler,
  amountConversion,
  approveHandler,
  brgeBalanceHandler,
  getUserRewardDebt,
  getUserTokens,
  poolsHandler,
  stakingHandler,
  unStakeTokenHandler,
  userInfoHandler
} from "../utils/staking/stakingHandler";
import { ReStakingPopup } from "./StakingPopup";

const Staking1 = ({ metaMaskAddress, connectMetamaskWallet }) => {
  const [chainId, setChainId] = useState("0x1");
  const [stakingPopup, setStakingPopup] = useState(false);
  const [unStakingPopup, setUnStakingPopup] = useState(false);
  const [brgeBalance, setBrgeBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [stakeAmount, setStakeAmount] = useState(0);
  const [userReward, setUserReward] = useState(0);
  const [userAllowance, setUserAllowance] = useState(0);
  const [yourStakeAmount, setYourStakeAmount] = useState(0);
  const [contractStakeAmount, setContractStakeAmount] = useState(0);
  const [actualRewards, setActualRewards] = useState(0);
  const [isStake, setIsStake] = useState(true);
  const [poolData, setPoolData] = useState({
    totalRewards: 0,
    maxApy: 0,
    totalStaked: 0
  });
  const POOL_ID = 0;
  const getUserChain = async () => {
    return await window?.ethereum?.request({
      method: "eth_chainId"
    });
  };
  useEffect(() => {
    handlePools();
    getUserChain().then((res) => {
      if (res !== "0x1") connectMetamaskWallet("0x1");
      setChainId(res);

      if (metaMaskAddress && chainId === "0x1") {
        handleBalance();
        handlerUserInfo();
        handleAllowance();
        getUserTokens({ address: metaMaskAddress, POOL_ID })
          .then((res) => {
            setUserReward(res);
          })
          .catch((err) => {});
        getUserRewardDebt({ address: metaMaskAddress, POOL_ID }).then((res) => {
          setActualRewards(res);
        });
      }
    });

    window.ethereum.on("chainChanged", (res) => {
      setChainId(res);
    });
  }, [metaMaskAddress, chainId]);

  const handlerUserInfo = () => {
    userInfoHandler({ address: metaMaskAddress, POOL_ID })
      .then((res) => {
        setYourStakeAmount(parseInt(res?.forUi ?? 0));
        setContractStakeAmount(res?.forContract ?? 0);
      })
      .catch(() => {});
  };

  const handleAllowance = () => {
    allowanceHandler({ address: metaMaskAddress })
      .then((res) => {
        setUserAllowance(res / 10 ** 18);
      })
      .catch((err) => {
        setUserAllowance(0);
      });
  };
  const handleBalance = () => {
    brgeBalanceHandler({ address: metaMaskAddress })
      .then((res) => {
        setBrgeBalance(res);
      })
      .catch((err) => {
        // toast.error(err.message);
      });
  };

  const handlePools = () => {
    poolsHandler()
      .then((res) => {
        let { totalToken, supply } = res[0];
        totalToken = totalToken / amountConversion(1);
        supply = supply / amountConversion(1);
        setPoolData({
          totalRewards: totalToken,
          maxApy: (totalToken * 365 * 100) / (supply * 40),
          totalStaked: supply
        });
      })
      .catch(async () => {
        try {
          await connectMetamaskWallet("0x1");
        } catch (_) {
        } finally {
          const chainId = await window.ethereum.request({
            method: "eth_chainId"
          });
          if (chainId !== "0x1") {
            toast.error("Please switch to Ethereum Mainnet");
          }
        }
      });
  };
  const handleStaking = async () => {
    if (chainId !== "0x1") {
      await connectMetamaskWallet("0x1");
    } else if (metaMaskAddress) {
      if (stakeAmount <= 0) {
        toast.error("Please enter valid amount");
        return;
      }
      if (brgeBalance < stakeAmount) {
        toast.error(`Insufficient Balance. Current Balance: ${brgeBalance}`);
        return;
      }

      if (yourStakeAmount > 0) {
        setStakingPopup(true);
        return;
      } else {
        await executeStaking();
      }
    } else {
      toast.error("Please connect wallet first");
    }
  };

  const executeStaking = async () => {
    setStakingPopup(false);
    setIsLoading(true);
    await stakingHandler({
      address: metaMaskAddress,
      token: "BRGE",
      amount: stakeAmount,
      POOL_ID
    });
    handleBalance();
    handlePools();
    handlerUserInfo();
    setIsLoading(false);
  };
  const executeUnstaking = async () => {
    setUnStakingPopup(false);
    setIsLoading(true);
    await unStakeTokenHandler({
      address: metaMaskAddress,
      stakeAmount: contractStakeAmount,
      POOL_ID
    });
    handlePools();
    handlerUserInfo();
    setIsLoading(false);
  };
  const handleUnstaking = async () => {
    if (metaMaskAddress) {
      setUnStakingPopup(true);
    } else {
      toast.error("Please connect wallet first");
    }
  };

  const StakingStatItem = ({ title, value, subscript }) => {
    return (
      <div className=" flex flex-col justify-center md:w-full md:min-w-[160px] min-w-[200px] total-staking-box md:rounded-[14px] rounded-[20px] px-3 py-4">
        <p className="md:text-[12px] text-gradient font-syne mb-2 text-[14px]">{title}</p>
        <p className="md:text-[10px] text-gradient font-syne mb-2 text-[10px]">{subscript}</p>
        <p className="md:text-[20px] text-[30px] font-grostek text-white font-bold">{value}</p>
      </div>
    );
  };
  return (
    <>
      <div className="flex items-center w-full justify-center staking-box-container bg-no-repeat text-white font-grostek text-3xl rounded-3xl two_million_conatiner h-[120px] font-semibold">
        2M $wBRGE REWARDS
      </div>
      <div className="md:w-full w-full mx-auto flex md:flex-wrap justify-center gap-8 my-4">
        <div className=" flex flex-2">
          <div className="grid  grid-cols-2 md:grid md:grid-cols-2 justify-center gap-8 ">
            <StakingStatItem
              title="TOTAL STAKED SO FAR"
              value={parseInt(poolData.totalStaked.toFixed(2))}
              // subscript="$wBRGE"
            />
            <StakingStatItem
              title="MAX APY"
              // value={`${parseInt(poolData.maxApy.toFixed(2))} %`}
              value={`0%`}
              // subscript="PA"
            />

            <StakingStatItem
              title="YOUR STAKING"
              value={parseInt(yourStakeAmount ?? 0)}
              // subscript="$wBRGE"
            />

            <StakingStatItem
              title="REWARDS ACCUMULATED"
              subscript="(basis max multiplier)"
              value={userReward}
              // subscript="$wBRGE"
            />
          </div>
        </div>
        <div className="flex-1 rounded-[20px] staking-box-container px-4 md:py-8 py-10 flex flex-col md:gap-10 gap-10  ">
          <div className="flex flex-wrap items-center justify-between md:justify-center md:gap-5">
            <h1 className="text-[28px] text-white font-bold md:text-center">Stake your $wBRGE</h1>
            <div className="flex  items-center justify-center gap-2 ">
              <div className="flex w-fit staking-options-bg  rounded-full text-white md:px-2 p-2 gap-2 items-center md:text-[14px]">
                <button
                  type="button"
                  onClick={() => {
                    setIsStake(true);
                    setIsLoading(false);
                  }}
                  className={
                    isStake
                      ? "rounded-full stake-option-checked px-3 py-1 cursor-pointer"
                      : "cursor-pointer px-3"
                  }
                >
                  Stake
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setIsStake(false);
                    setIsLoading(false);
                  }}
                  className={
                    !isStake
                      ? "rounded-full stake-option-checked px-3 py-1 cursor-pointer"
                      : "cursor-pointer px-3"
                  }
                >
                  Unstake
                </button>
              </div>
              <div className="text-white">on</div>
              <img src={EthereumIcon} alt="" width={"20"} />
              <div className="text-white">ETH</div>
            </div>
          </div>
          <div className="flex w-full items-start text-white">
            <img src={Border} alt="" className="mt-[-20px]" />
            <div className="flex-1 text-inter font-semibold md:text-[12px] relative">
              <div className=" text-end rounded-l-xl py-2 pr-4 max-h-[35px] bg-[#4DD15A]">
                7 DAYS
              </div>
              <div className="text-end pr-4 mt-1">{0}% APR</div>
              <img src={Border} alt="" className="mt-[-20px] absolute right-0 top-0" />
            </div>

            <div className="flex-1 text-inter font-semibold md:text-[12px] relative">
              <div className=" bg-[#16AD12] text-end py-2 pr-4 max-h-[35px]">15 DAYS</div>
              <div className="text-end pr-4 mt-1">0% APR</div>
              <img src={Border} alt="" className="mt-[-20px] absolute right-0 top-0" />
            </div>

            <div className="flex-1 text-inter font-semibold md:text-[12px] relative">
              <div className=" bg-[#16AD12] text-end py-2 pr-4 max-h-[35px]">30 DAYS</div>
              <div className="text-end pr-4 mt-1">0% APR</div>
              <img src={Border} alt="" className="mt-[-20px] absolute right-0 top-0" />
            </div>

            <div className="flex-1 text-inter font-semibold md:text-[12px]">
              <div className=" bg-[#16AD12] text-end rounded-r-xl py-2 pr-4 max-h-[35px]">
                30+ DAYS
              </div>
              <div className="text-end pr-4 mt-1">0% APR</div>
            </div>
            <img src={Border} alt="" className="mt-[-20px]" />
          </div>
          <div className="flex justify-content-between text-white staking-amount-box p-4 py-3 text-[rgba(255, 255, 255, 0.81)] ">
            <div>
              <p
                className="text-[12px] mb-1 font-semibold font-syne"
                style={{
                  color: "rgba(255, 255, 255, 0.40)"
                }}
              >
                {isStake ? "Enter amount to stake" : "Amount to unstake"}
              </p>
              <input
                type="number"
                step="1"
                className="amount_input bg-transparent border-none font-bold font-grostek !w-max  pl-0 pr-0 md:text-[20px] text-[30px] "
                value={isStake ? stakeAmount : yourStakeAmount}
                disabled={!isStake}
                onChange={(e) => {
                  setStakeAmount(parseInt(e.target.value));
                }}
              />
              {/* <p className="md:text-[20px] text-[30px] font-bold font-grostek">
            52,223,4567
          </p> */}
            </div>
            <div className="sm:hidden flex flex-col gap-2">
              {/* biome-ignore lint/a11y/useButtonType: <explanation> */}
              {isStake &&
                metaMaskAddress &&
                (userAllowance >= stakeAmount ? (
                  // biome-ignore lint/a11y/useButtonType: <explanation>
                  <button
                    // type="button"
                    className="landing-page-hero-content--button"
                    onClick={() => handleStaking()}
                  >
                    {isLoading ? (
                      <div className="flex items-center justify-center gap-2">
                        Processing
                        <ThreeDots width="16" height="16" color="#ffffff" className="pt-2" />
                      </div>
                    ) : (
                      "Stake"
                    )}
                  </button>
                ) : (
                  // biome-ignore lint/a11y/useButtonType: <explanation>
                  <button
                    className=" landing-page-hero-content--button"
                    onClick={async () => {
                      setIsLoading(true);
                      if (chainId !== "0x1") {
                        await connectMetamaskWallet("0x1");
                      } else if (metaMaskAddress) {
                        await approveHandler({
                          stakeAmount
                        });
                        const allowance = await allowanceHandler({
                          address: metaMaskAddress
                        });
                        setUserAllowance(allowance / 10 ** 18);
                      } else {
                        toast.error("Could not approve!");
                      }
                      setIsLoading(false);
                    }}
                  >
                    {isLoading ? (
                      <div className="flex items-center justify-center gap-2">
                        Processing
                        <ThreeDots width="16" height="16" color="#ffffff" className="pt-2" />
                      </div>
                    ) : (
                      "Approve"
                    )}
                  </button>
                ))}
              {!isStake && metaMaskAddress && (
                // biome-ignore lint/a11y/useButtonType: <explanation>
                <button
                  className=" landing-page-hero-content--button"
                  onClick={async () => {
                    if (chainId !== "0x1") {
                      await connectMetamaskWallet("0x1");
                    } else if (Number(contractStakeAmount) === 0)
                      toast.error("Your staking balance is 0.");
                    else handleUnstaking();
                  }}
                >
                  {isLoading ? (
                    <div className="flex items-center justify-center gap-2">
                      Processing
                      <ThreeDots width="16" height="16" color="#ffffff" className="pt-2" />
                    </div>
                  ) : (
                    "Unstake"
                  )}
                </button>
              )}
              {!metaMaskAddress && (
                <ConnectMetaMaskWallet
                  onConnectClick={() => connectMetamaskWallet("0x1")}
                  address={metaMaskAddress}
                />
              )}
              <div className="flex gap-2 self-end text-end">
                <p className="font-grostek text-sm font-normal ">
                  Balance: {metaMaskAddress ? brgeBalance?.toFixed(2) : 0}
                </p>
                {/* biome-ignore lint/a11y/useButtonType: <explanation> */}
                <button
                  className="text-purple-500"
                  onClick={() => {
                    if (metaMaskAddress) {
                      setStakeAmount(parseInt(brgeBalance));
                    }
                  }}
                >
                  Max
                </button>
              </div>
              {/* {stakeAmount > brgeBalance && (
            <p className="text-red-500 text-[.75rem] leading-none">Insufficent Balance</p>
          )} */}
            </div>
          </div>
          <div className="sm:flex flex-wrap hidden justify-end items-center gap-3 justify-center ">
            <div className="sm:block md:hidden flex justify-end items-center gap-3">
              {/* biome-ignore lint/a11y/useButtonType: <explanation> */}
              {isStake &&
                metaMaskAddress &&
                (userAllowance >= stakeAmount ? (
                  // biome-ignore lint/a11y/useButtonType: <explanation>
                  <button
                    // type="button"
                    className=" landing-page-hero-content--button"
                    onClick={() => handleStaking()}
                  >
                    {isLoading ? (
                      <div className="flex items-center justify-center gap-2">
                        Processing
                        <ThreeDots width="16" height="16" color="#ffffff" className="pt-2" />
                      </div>
                    ) : (
                      "Stake"
                    )}
                  </button>
                ) : (
                  // biome-ignore lint/a11y/useButtonType: <explanation>
                  <button
                    // type="button"
                    className=" landing-page-hero-content--button"
                    onClick={async () => {
                      setIsLoading(true);
                      if (chainId !== "0x1") {
                        await connectMetamaskWallet("0x1");
                      } else if (metaMaskAddress) {
                        await approveHandler({
                          stakeAmount
                        });
                      } else {
                        toast.error("Could not approve!");
                      }
                      setIsLoading(false);
                    }}
                  >
                    {isLoading ? (
                      <div className="flex items-center justify-center gap-2">
                        Processing
                        <ThreeDots width="16" height="16" color="#ffffff" className="pt-2" />
                      </div>
                    ) : (
                      "Approve"
                    )}
                  </button>
                ))}
              {!isStake && metaMaskAddress && (
                // biome-ignore lint/a11y/useButtonType: <explanation>
                <button
                  // type="button"
                  className=" landing-page-hero-content--button"
                  onClick={async () => {
                    if (chainId !== "0x1") {
                      await connectMetamaskWallet("0x1");
                    } else if (Number(contractStakeAmount) === 0)
                      toast.error("Your staking balance is 0.");
                    else handleUnstaking();
                  }}
                >
                  {isLoading ? (
                    <div className="flex items-center justify-center gap-2">
                      Processing
                      <ThreeDots width="16" height="16" color="#ffffff" className="pt-2" />
                    </div>
                  ) : (
                    "Unstake"
                  )}
                </button>
              )}
              {!metaMaskAddress && (
                <ConnectMetaMaskWallet
                  onConnectClick={() => connectMetamaskWallet("0x1")}
                  address={metaMaskAddress}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {stakingPopup && (
        <ReStakingPopup
          onCancel={() => setStakingPopup(false)}
          onProceed={() => executeStaking()}
          type="stake"
        />
      )}
      {unStakingPopup && (
        <ReStakingPopup
          onCancel={() => setUnStakingPopup(false)}
          onProceed={() => executeUnstaking()}
          type="Unstake"
          unStakeAmount={Number(actualRewards) + Number(yourStakeAmount)}
        />
      )}
    </>
  );
};

export default Staking1;
