import Web3 from "web3";
import { appChains, getWeb3UrlByTag } from "./chains";
import ETH_ABI from "./eth";
import AVAX_ABI from "./avax";
import TOKEN_ABI from "./tokenAbi";

const ethChain = appChains[0];
const avaxChain = appChains[2];
const arbiChain = appChains[3];
const baseChain = appChains[4];

export const ethWeb3 = new Web3(getWeb3UrlByTag(ethChain.tag));
export const avaxWeb3 = new Web3(getWeb3UrlByTag(avaxChain.tag));
export const arbiWeb3 = new Web3(getWeb3UrlByTag(arbiChain.tag));
export const baseWeb3 = new Web3(getWeb3UrlByTag(baseChain.tag));

export const ethContractHandler = new ethWeb3.eth.Contract(ETH_ABI, ethChain.contractAddress);
export const avaxContractHandler = new avaxWeb3.eth.Contract(AVAX_ABI, avaxChain.contractAddress);
export const arbiContractHandler = new arbiWeb3.eth.Contract(AVAX_ABI, arbiChain.contractAddress);
export const baseContractHandler = new baseWeb3.eth.Contract(AVAX_ABI, baseChain.contractAddress);

export const fetchBalanceHandler = async ({ token, type, address }) => {
  try {
    let promise;
    if (type === "ETH")
      promise = await ethContractHandler.methods.tokenContracts(token.toUpperCase()).call();
    else if (type === "AVAX")
      promise = await avaxContractHandler.methods.tokenContracts(token.toUpperCase()).call();
    else if (type === "ARBI")
      promise = await arbiContractHandler.methods.tokenContracts(token.toUpperCase()).call();
    else if (type === "BASE")
      promise = await baseContractHandler.methods.tokenContracts(token.toUpperCase()).call();
    if (promise !== "0x0000000000000000000000000000000000000000") {
      let tokenContractHandler = new ethWeb3.eth.Contract(TOKEN_ABI, promise);
      let ans = await tokenContractHandler.methods.balanceOf(address).call();
      return ans;
    }
  } catch (err) {
    throw err;
  }
};
