import React, { useEffect, useState } from "react";
import Timer from "../Timer/Timer";
import ProgressBar from "../ProgressBar/ProgressBar";
import { fetchEthBalance } from "../../utils/balance&transfer";
import { toast } from "react-toastify";
import {
  allowanceHandler,
  approveHandler,
  buyTokenHandler,
  buyWLTokenHandler,
  preValidateHandler,
  preValidateWLHandler
} from "../../utils/tetherhandler";
import { ThreeDots } from "react-loader-spinner";

const PresaleBox = (props) => {
  const { connectMetamaskWallet, metaMaskAddress, saleType } = props;
  const [transferAmount, setTransferAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const tranferUSDTHandler = async () => {
    setIsLoading(true);
    try {
      let allowanceAmount = await allowanceHandler({
        address: metaMaskAddress
      });
      if (allowanceAmount >= transferAmount) {
        let isValidated = false;
        try {
          isValidated = await preValidateHandler({
            amount: transferAmount,
            address: metaMaskAddress
          });
        } catch (e) {
          console.log(e);
        }

        if (isValidated) {
          await buyTokenHandler({ amount: transferAmount, address: metaMaskAddress });
          toast.success(`${transferAmount} token buy successfully`);
        } else {
          toast.error("Cannot purchase tokens at this time. Please try again later.");
        }
      } else {
        let isApprove = await approveHandler({
          amount: Number(transferAmount),
          address: metaMaskAddress
        });
        if (isApprove) tranferUSDTHandler();
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(err.message);
    }
  };
  const tranferWLUSDTHandler = async () => {
    setIsLoading(true);
    try {
      // let isValidated = false;
      // try {
      //   isValidated = await preValidateWLHandler({
      //     amount: transferAmount,
      //     address: metaMaskAddress
      //   });
      // } catch (_) {}

      // if (isValidated) {
      let allowanceAmount = await allowanceHandler({
        address: metaMaskAddress
      });
      if (allowanceAmount >= transferAmount) {
        await buyWLTokenHandler({ amount: transferAmount, address: metaMaskAddress });
        toast.success(`${transferAmount} tokens bought successfully!`);
      } else {
        let isApprove = await approveHandler({
          amount: Number(transferAmount),
          address: metaMaskAddress
        });
        if (isApprove) tranferWLUSDTHandler();
      }
      // } else {
      //   toast.error("You are not whitelisted for this sale");
      // }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      try {
        const message = err.message.split("\n")[0].split("reverted:")[1];
        toast.error(message);
      } catch (e) {
        console.log(err);
        console.log(e);
        toast.error("Something went wrong! Please try again or contact support.");
      }
    }
  };

  return (
    <div className="flex flex-col gap-4 justify-center items-center px-6 py-8 rounded-xl swap_subheading relative md:px-3 md:py-6  md:min-w-fit min-w-[500px] text-white bg-gradient12">
      <p className="text-2xl text-white-A700 md:text-xl">{saleType}</p>
      <Timer
        heading="Starts in"
        targetDate={
          saleType === "Whitelist Sale"
            ? new Date("March 31, 2024 15:00:00 GMT+0000")
            : new Date("March 31, 2024 19:00:00 GMT+0000")
        }
      />
      <ProgressBar
        total={"30000"}
        progressed={30000}
        progressLabel="USDT"
        barContainerClass="h-[14px]"
        progressLineClass="h-[14px]"
      />
      <div className="flex flex-col gap-2 !justify-start w-full mt-4 md:mt-2">
        <div className="flex flex-col gap-1 bg-[#ffffff0c] border-[#ffffff5e] px-4 py-3 md:!px-3 md:!py-2 rounded-lg">
          <div className="flex items-center justify-between text-[#ffffff54] md:text-sm">
            <p>Amount</p>
            <p>Max: {props.usdtBalance}</p>
          </div>
          <div id="input_container" className="w-full rounded-lg relative">
            <input
              className="border-none bg-transparent outline-none w-full text-2xl pl-0 pr-20 md:text-lg"
              value={transferAmount}
              onChange={(e) => {
                setTransferAmount(e?.target?.value);
              }}
              type="number"
            />
            <button
              className="absolute right-0 z-20 top-[50%] translate-y-[-50%] text-[#A689FF] font-semibold px-3 py-2 md:!py-1 md:text-xs border-1 border-[#ffffff4d] rounded-full"
              onClick={() => {
                if (props.usdtBalance > 0) {
                  setTransferAmount(props.usdtBalance);
                }
              }}>
              MAX
            </button>
          </div>
        </div>

        <button
          className="landing-page-hero-content--button font-semibold mt-2 md:text-sm"
          onClick={() => {
            toast.info("All tokens have been sold out!");
          }}>
          Sold out!
        </button>
        {/* <button
          className="landing-page-hero-content--button font-semibold mt-2 md:text-sm"
          onClick={() => {
            if (metaMaskAddress) {
              if (transferAmount < 150 || transferAmount > 1500) {
                toast.error("Amount should be greater than 150 and less than 1500");
              } else {
                if (saleType === "Presale") tranferUSDTHandler();
                else {
                  tranferWLUSDTHandler();
                }
              }
            } else {
              connectMetamaskWallet();
            }
          }}>
          {isLoading ? (
            <div className="flex items-center justify-center gap-2">
              Processing
              <ThreeDots width="16" height="16" color="#ffffff" className="pt-2" />
            </div>
          ) : metaMaskAddress ? (
            "Transfer"
          ) : (
            "Connect Wallet"
          )}
        </button> */}
      </div>
    </div>
  );
};

export default PresaleBox;
