import { toast } from "react-toastify";
import Web3 from "web3";
import { TETHER_ABI } from "./tetherAbi";
import { SALE_ABI } from "./presaleAbi";

const web3 = new Web3(window.ethereum);
const usdtAddress = "0xdac17f958d2ee523a2206206994597c13d831ec7";
const preSaleAddress = "0xfc789ee784e352b7a6b3eb79b46814b595c900a7";
const tetherContract = new web3.eth.Contract(TETHER_ABI, usdtAddress);
const preSaleContract = new web3.eth.Contract(SALE_ABI, preSaleAddress);
export const amountConversion = (amount) => {
  return String(amount * 10 ** 6);
};

export const allowanceHandler = async ({ address }) => {
  let amount = await tetherContract.methods.allowance(address, preSaleAddress).call();
  return amount / 10 ** 6;
};

export const approveHandler = async ({ amount, address }) => {
  try {
    const gasPrice = await web3.eth.getGasPrice();
    const amountInSmallestUnit = amountConversion(amount);
    console.log({ amountInSmallestUnit });
    console.log(typeof amountInSmallestUnit, amountInSmallestUnit);
    const tx = await tetherContract.methods
      .approve(preSaleAddress, amountInSmallestUnit)
      .send({ from: address });
    // const data = tx.encodeABI();
    // const gasLimit = await tx.estimateGas({ from: address });
    // const txHash = await web3.eth.sendTransaction({
    //   to: usdtAddress,
    //   gas: gasLimit, // Adjust the gas limit as needed
    //   gasPrice: gasPrice,
    //   data: data,
    //   from: address
    // });
    toast.success(`${amount} tokens approved`);
  } catch (err) {
    toast.error(err);
  }
};

export const preValidateHandler = async ({ amount, address }) => {
  let bool = await preSaleContract.methods
    ._preValidatePurchase(address, amountConversion(amount))
    .call();
  return bool;
};
export const preValidateWLHandler = async ({ amount, address }) => {
  let bool = await preSaleContract.methods
    ._preValidateWLPurchase(address, amountConversion(amount))
    .call();
  return bool;
};
export const buyTokenHandler = async ({ amount, address }) => {
  let bool = await preSaleContract.methods
    .buyTokens(amountConversion(amount))
    .send({ from: address });
  return bool;
};
export const buyWLTokenHandler = async ({ amount, address }) => {
  let bool = await preSaleContract.methods
    .buyWLTokens(amountConversion(amount))
    .send({ from: address });
  return bool;
};
export const tetherRaisedHandler = async () => {
  try {
    let amount = await preSaleContract.methods._tetherRaised().call();
    return amount;
  } catch (err) {
    throw err;
  }
};
export const checkTokenHoldingHandler = async () => {
  try {
    let isWhitelisted = await preSaleContract.methods.checkTokenHolding().call();
    return isWhitelisted;
  } catch (err) {
    throw err;
  }
};
export const fetchUSDTBalanceHandler = async ({ address }) => {
  try {
    let amount = await tetherContract.methods.balanceOf(address).call();
    return amount;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
