import React from "react";
import Text from "./Text";
import { transformString } from "../utils";
import { appChains } from "../utils/chains";
import { AiOutlineMail } from "react-icons/ai";
import Telegram from "../assets/Telegram.png";
import Twitter from "../assets/Twitter.png";
import Discord from "../assets/Discord.png";

const FooterBar = () => {
  const tokensData = appChains.filter((item) => item.name !== "Bitcoin");
  const TokenItem = ({ icon, token, link }) => {
    const transformedString = transformString(token);
    return (
      <div className="flex gap-2 max-w-[160px] justify-center items-center leading-loose  text-[12px] text-[#E4DCFF] font-grostek  text-center ">
        <img src={icon} className="w-[20px]" alt={token} />
        <span className="col-span-3">
          <a href={link} target="_blank" rel="noreferrer" className="hover:underline">
            {transformedString}
          </a>
        </span>
      </div>
    );
  };
  return (
    <div className="footer-container sm:pl-4">
      <div className=" container mx-auto md:grid md:grid-cols-2 sm:flex sm:flex-col sm:gap-4 flex justify-between pt-20 gap-10 border-b border-[#71777D] pb-8 text-white ">
        <div className="md:col-span-2">
          <Text
            className="text-[36px] text-blue-200 md:pr-0 pr-12 cursor-pointer mb-6 "
            size="txtPlusJakartaSansRomanBold36"
          >
            <span className="text-purple-700 font-syne text-left font-bold">Ord</span>
            <span className="text-white-A700 font-syne text-left font-normal">Bridge</span>
          </Text>
          <p className="text-[20px] font-manrope mb-3">
            A crosschain bridge to move BRC-20 <br className="md:hidden" /> to any chain.
          </p>
          <a
            href="mailto:tech@ordbridge.io"
            className="text-[20px] text-white flex items-center gap-3 font-manrope"
          >
            <span>
              <AiOutlineMail />
            </span>{" "}
            tech@ordbridge.io
          </a>
        </div>
        <div className="">
          <p className="font-grostek text-[#92989F] text-[24px] font-bold mb-6 h-[47px] sm:mb-0 sm:h-[30px]">
            General
          </p>
          <a
            href="https://ordbridge.gitbook.io/ordbridge-a-2-way-bridge-between-brc20-and-erc20/"
            target="_blank"
            className="block font-manrope my-3 sm:my-2"
            rel="noreferrer"
          >
            Gitbook Docs
          </a>
          <a
            href="https://ordbridge.gitbook.io/ordbridge-a-2-way-bridge-between-brc20-and-erc20/how-it-works/bridging-process"
            target="_blank"
            className="block font-manrope my-3 sm:!mb-0 sm:!mt-2"
            rel="noreferrer"
          >
            How does bridge work?
          </a>
        </div>
        <div className="">
          <div className="h-[45px] mb-6 sm:hidden" />
          <a
            href="https://ordbridge.gitbook.io/ordbridge-a-2-way-bridge-between-brc20-and-erc20/how-it-works/technical-overview/brc-20-and-erc-20"
            className="block font-manrope my-3 sm:!mb-2 sm:!mt-0"
            target="_blank"
            rel="noreferrer"
          >
            What is BRC-20
          </a>
          <a href="/bridge" className="block font-manrope my-3 sm:my-2">
            Bridge BRC-20 tokens
          </a>
        </div>
        <div>
          <p className="font-grostek text-[#92989F] text-[24px] font-bold mb-6 h-[47px] sm:mb-2 sm:h-[30px]">
            Social media links
          </p>
          <div className="flex gap-10 sm:gap-12">
            <a href="https://t.me/ordbridgefi">
              <img src={Telegram} alt="" className="h-[40px] w-[40px]" />
            </a>
            <a href="https://twitter.com/OrdBridge" className="h-[40px] w-[40px]">
              <img src={Twitter} alt="" />
            </a>
            <a href="https://discord.gg/QGJwPDnxQ8" className="h-[40px] w-[40px]">
              <img src={Discord} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="container mx-auto flex justify-center py-4 flex-wrap gap-2">
        {tokensData.map((item) => (
          <TokenItem icon={item.icon} link={item.tokenLink} token={item.tokenAddress} />
        ))}
      </div>
    </div>
  );
};

export default FooterBar;
