import React from "react";
import JI1 from "../assets/ord-thumb-3.png";
import LaunchBg from "../assets/launch-bg.png";
import LaunchBgMobile from "../assets/launch-bg-mobile.png";
import { Tooltip } from "flowbite-react";
import useMediaQuery from "../hooks/useMediaQuery";
import { Link } from "react-router-dom";

const Launchpad = () => {
  const isMob = useMediaQuery("(max-width:630px)");
  const JoinSteps = ({ heading, text, number }) => {
    return (
      <div className="join-steps-container !max-w-1/2 !min-w-1/2">
        <div>
          <img src={JI1} height="70px" width="70px" alt="" />
        </div>
        <h1 className="text-gradient font-syne font-bold text-xl mt-2">{heading}</h1>

        <div className="text-[#CCCAD3] font-grostek max-w-[80%] mt-3">{text}</div>
        <div className="flex items-end justify-between ">
          <div>
            {/* biome-ignore lint/a11y/useButtonType: <explanation> */}
            {/* <button className="landing-page-hero-content--button">Go to BullStarter</button> */}
          </div>
          <p className="landing-join-number ">{number}</p>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="launchpad-hero">
        <div className="landing-page-hero-content">
          <h1 className="landing-page-hero-content--heading">
            Get Early Access
            <br />
            To Innovation
          </h1>
          <p className="landing-page-hero-content--para">
            Explore projects having the same spirit of innovation as OrdBridge and groundbreaking
            ideas revolutionising in BRC20 ecosystem.
          </p>
          <div className="landing-page-hero-content-buttons sm:flex-col sm:w-[100%] sm:px-6">
            {/* biome-ignore lint/a11y/useButtonType: <explanation> */}
            <button
              className=" landing-page-hero-content--button--alt sm:w-[100%]"
              onClick={() => {
                window.open("https://medium.com/@techordbridge/597d84e28c8b", "_blank");
              }}
            >
              How to invest
            </button>
            <Link to="/projects">
              <button className="landing-page-hero-content--button sm:w-[100%]">
                Explore projects
              </button>
            </Link>
          </div>
        </div>
        <div className="landing-page-hero-image-wrapper " />
      </div>
      <div className="my-20">
        <h1 className="launchpad-join-heading">Participate in IDOs based on</h1>
        <div className="flex sm:flex-wrap gap-y-10 container mx-auto gap-10">
          <JoinSteps
            heading="Privileged access"
            text="Get privileged access to the IDOs basis your holding of $BRGE token on any chain. For every project, there will be different threshold, whitelist criteria and max cap per wallet. Details will be shared along with IDO."
            number={"01"}
          />
          <JoinSteps
            heading="Public access"
            text="Participate in public presale of groundbreaking ideas which push BRC20 ecosystem further. The sale will be exclusive to OrdBridge Launchpad and listing across chains will follow."
            number={"02"}
          />
        </div>
      </div>
      <div className="flex bg-black relative justify-between mt-20">
        <div className="launch-gradient-area launch-gradient-area--top sm:hidden" />
        <div className="launch-gradient-area launch-gradient-area--bottom sm:hidden" />
        <div className=" absolute h-full sm:shadow sm:max-w-full max-w-[45%] sm:left-0 left-[6vw] flex flex-col justify-center sm:items-center z-10  sm:ml-0 ml-[45px] sm:px-4">
          <h1 className="sm:text-center text-[40px] sm:text-[30px] text-white font-syne font-semibold mb-5 ">
            Want to launch your project on <span className="text-[#794EFF]">Ord</span>Bridge
          </h1>
          <div>
            <button
              className="landing-page-hero-content--button w-[300px]"
              onClick={() => {
                window.open("https://0hmqaeomk2j.typeform.com/to/ABva9c7x", "_blank");
              }}
            >
              Apply Now
            </button>
          </div>
        </div>
        <div className="sm:hidden" />
        <div className="">
          <img
            className="sm:hidden block sm:min-h-[350px] min-h-[650px]"
            style={{ filter: "brightness(103%)" }}
            src={LaunchBg}
            alt="launch"
          />

          <img
            className="sm:block hidden min-h-[350px]"
            style={{ filter: "brightness(103%)" }}
            src={LaunchBgMobile}
            alt="launch"
          />
        </div>
      </div>
    </div>
  );
};

export default Launchpad;
