import React, { useEffect, useState } from "react";

const Timer = ({ targetDate, heading }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="flex justify-center items-center gap-3 w-full py-3 rounded bg-gradient10 border-ord">
      <p className="font-syne text-2xl font-light text-[#f2f2f27c] md:text-[1rem]">{heading}</p>
      <div className="flex items-center gap-2 md:!gap-1">
        <div className="md:w-[30px] md:h-[30px] md:text-sm w-[50px] h-[50px] flex items-center justify-center font-bold bg-gradient11 text-white rounded-md text-lg border-ord">
          {timeLeft.days !== undefined && `${timeLeft.days < 10 ? "0" : ""}${timeLeft.days} `}
        </div>
        <p className="text-2xl md:text-xl text-[#f2f2f273]">:</p>
        <div className="md:w-[30px] md:h-[30px] md:text-sm w-[50px] h-[50px] flex items-center justify-center font-bold bg-gradient11 text-white rounded-md text-lg border-ord">
          {`${timeLeft.hours < 10 ? "0" : ""}${timeLeft.hours} `}
        </div>
        <p className="text-2xl md:text-xl text-[#f2f2f273]">:</p>
        <div className="md:w-[30px] md:h-[30px] md:text-sm w-[50px] h-[50px] flex items-center justify-center font-bold bg-gradient11 text-white rounded-md text-lg border-ord">
          {" "}
          {`${timeLeft.minutes < 10 ? "0" : ""}${timeLeft.minutes} `}
        </div>
        <p className="text-2xl md:text-xl text-[#f2f2f273]">:</p>
        <div className="md:w-[30px] md:h-[30px] md:text-sm w-[50px] h-[50px] flex items-center justify-center font-bold bg-gradient11 text-white rounded-md text-lg border-ord">
          {" "}
          {`${timeLeft.seconds < 10 ? "0" : ""}${timeLeft.seconds}`}
        </div>
      </div>
    </div>
  );
};

export default Timer;
