import "../styles/home.page.css";
import React, { useCallback, useEffect, useState } from "react";
import { SwapPopup } from "../components/SwapPopup";
import axios from "axios";
import { UnisatAlertModal } from "../components/UnisatAlertModal";
import useMediaQuery from "../hooks/useMediaQuery";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { appChains } from "../utils/chains";

const HomePage = ({
  step,
  setStep,
  toChain,
  setToChain,
  fromChain,
  setFromChain,
  unisatAddress,
  connectUnisatWallet,
  setType,
  type,
  metaMaskAddress,
  connectMetamaskWallet,
  phantomAddress,
  connectPhantomWallet,
  session_key,
  pendingEntryPopup,
  setPendingEntryPopup,
  isMobile,
  setIsMobile,
  setUnisatAddress,
  setMetamaskAddress,
  userDetails,
  setUserDetails,
  walletUpdate
}) => {
  // Search Params STATE
  const [searchParams] = useSearchParams();
  const tokenParam = searchParams.get("token") ?? "";
  const amountParam = searchParams.get("amount") ?? "";
  const fromChainParam = searchParams.get("fromChain") ?? "";
  const toChainParam = searchParams.get("toChain") ?? "";

  const [tokenList, setTokenList] = useState([]);
  const [token, setToken] = useState("");
  const isMob = useMediaQuery("(max-width:630px)");
  // const [isScrolledToTop, setIsScrolledToTop] = useState(true);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrolledToTop = window.scrollY === 0;
  //     setIsScrolledToTop(scrolledToTop);
  //   };

  //   // Attach the event listener when the component mounts
  //   window.addEventListener('scroll', handleScroll);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []); // Empty dependency array ensures that the effect runs only once when the component mounts

  // async function copyToClipboard(text) {
  //   try {
  //     const toCopy = text.slice(0, text.length);
  //     await navigator.clipboard.writeText(toCopy);
  //     toast.success('Copied Value to Clipboard');
  //   } catch (err) {}
  // }

  const handleTokenList = async () => {
    try {
      const res = await axios.get("https://api.ordbridge.io/bapi/bridge/tickers_controlled");
      setTokenList(res.data);
      setToken(res.data?.[0]);
    } catch (error) {
      console.log("Error while fetching token list in handleTokenList", error);
      toast.error(error?.message ?? "Some error occurred");
    }
  };

  useEffect(() => {
    handleTokenList();
  }, []);

  useEffect(() => {
    if (tokenParam && tokenList?.length) {
      const isValidToken = tokenList?.includes(tokenParam);
      if (isValidToken) {
        setToken(tokenParam);
      } else {
        toast.error("Invalid token param");
      }
    }
    if (fromChainParam && toChainParam && fromChainParam !== toChainParam) {
      const fromChainEle = appChains?.find((ele) => {
        return ele?.tag === fromChainParam;
      });
      const toChainEle = appChains?.find((ele) => {
        return ele?.tag === toChainParam;
      });
      if (fromChainEle?.tag && toChainEle?.tag) {
        setFromChain(fromChainEle);
        setToChain(toChainEle);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenParam, tokenList, fromChainParam, toChainParam]);

  const handleAccounts = async () => {
    // To Check Unisat is connected after page refreshing
    if (!isMob && window.unisat) {
      try {
        var UnisatAccount = await window.unisat.getAccounts();
        if (UnisatAccount?.length > 0) {
          setUnisatAddress(UnisatAccount[0]);
        }
      } catch (err) {
        toast.error(
          err.code === 4001
            ? "Seems like your Unisat wallet is not setup. Please setup Unisat to be able to transfer from BRC-20 chain."
            : err.message
        );
      }
    }

    // To Check Metamask is connected after page refreshing
    try {
      var MetamaskAccount = await window.ethereum.request({
        method: "eth_accounts"
      });
      if (MetamaskAccount?.length > 0) {
        setMetamaskAddress(MetamaskAccount[0]);
      }
    } catch (err) {
      console.log(err.message);
    }
    let payload = {};
    if (MetamaskAccount?.length > 0) {
      payload = { metamask_address: MetamaskAccount[0] };
    }
    if (UnisatAccount?.length > 0) {
      payload = { ...payload, unisat_address: UnisatAccount[0] };
    }
    if (MetamaskAccount?.length > 0 || UnisatAccount?.length > 0) {
      setUserDetails(payload);
      walletUpdate(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    handleAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleWalletAddress = (type, fieldName, fieldValue) => {
    setUserDetails({
      ...userDetails,
      [fieldName]: fieldValue
    });
    if (type === "metamask") {
      setMetamaskAddress(fieldValue);
    }
    if (type === "unisat") {
      setUnisatAddress(fieldValue);
    }
  };

  const handleDisconnects = useCallback(async () => {
    if (window?.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts?.length === 0) {
          handleWalletAddress("metamask", "metamask_address", "");
        } else if (accounts?.[0] !== metaMaskAddress) {
          handleWalletAddress("metamask", "metamask_address", accounts?.[0]);
        }
      });
    }
    if (window?.unisat) {
      window?.unisat?.on("accountsChanged", (accounts) => {
        if (accounts?.length === 0) {
          handleWalletAddress("unisat", "unisat_address", "");
        } else if (accounts?.[0] !== unisatAddress) {
          handleWalletAddress("unisat", "unisat_address", accounts?.[0]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  useEffect(() => {
    handleDisconnects();
    // return handleDisconnects;
  }, [handleDisconnects]);

  return (
    <>
      <div className="">
        <div className="pt-32 min-h-screen swap_mobile_container sm:pt-0">
          <SwapPopup
            shouldAutoConnect={!(fromChainParam && toChainParam)}
            initialTokenValue={amountParam && !isNaN(amountParam) ? amountParam : null}
            step={step}
            setStep={setStep}
            token={token}
            setToken={setToken}
            toChain={toChain}
            setToChain={setToChain}
            fromChain={fromChain}
            setFromChain={setFromChain}
            appChains={appChains}
            tokenList={tokenList}
            setType={setType}
            type={type}
            unisatAddress={unisatAddress}
            metaMaskAddress={metaMaskAddress}
            phantomAddress={phantomAddress}
            connectUnisatWallet={connectUnisatWallet}
            connectMetamaskWallet={connectMetamaskWallet}
            connectPhantomWallet={connectPhantomWallet}
            session_key={session_key}
            pendingEntryPopup={pendingEntryPopup}
            setPendingEntryPopup={setPendingEntryPopup}
          />
        </div>

        {/* {!isScrolledToTop && (
          <button
            className="border-1 rounded-full px-4 pt-2 pb-2 mt-2 fixed bottom-8 right-4"
            style={{
              borderWidth: '.001rem !important',
              borderColor: '#281a5e',
              background:
                'linear-gradient(0deg, rgba(150,112,255,1) 0%, rgba(26,20,67,1) 1%, rgba(22,20,63,1) 100%)',
              zIndex: '10000'
            }}>
            <div
              className="flex justify-center items-center cursor-pointer"
              onClick={() => {
                window.scrollTo(0, 0);
              }}>
              <span className="font-syne !text-base uppercase font-normal text-white">
                Go to top
              </span>
              <FaArrowUp className="ml-2 text-[#794EFF]" />
            </div>
          </button>
        )} */}

        {isMobile && <UnisatAlertModal setIsMobile={setIsMobile} />}
      </div>
    </>
  );
};

export default HomePage;
