import React from "react";
import UnisatIcon from "../../assets/unisat.png";
import "../../styles/connect-wallet.css";

const ConnectUnisatWallet = ({ onConnectClick, address, text, isNav = false, className }) => {
  if (address) {
    const firstHalf = address && address.substring(0, 5);
    const lastHalf = address && address.substring(address.length - 5, address.length - 1);
    const truncatedAddress = firstHalf + "..." + lastHalf;
    return (
      <button
        className={`flex gap-1 justify-center w-[190px] landing-page-hero-content--button--alt font-semibold ${className} `}>
        <img src={UnisatIcon} style={{ width: "20px" }} alt="" /> {truncatedAddress}
      </button>
    );
  }

  if (!address) {
    return (
      <button
        className={`landing-page-hero-content--button--alt ${
          !isNav ? "border-none" : ""
        } ${className}`}
        onClick={onConnectClick}>
        Connect Unisat
      </button>
    );
  }
};

export default ConnectUnisatWallet;
