/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PresaleBox from "../components/PresaleBox/PresaleBox";
import { fetchWbrgeBalance } from "../utils/balance&transfer";
import Confetti from "react-confetti";
import useWindowSize from "../hooks/useWindowSize";
import {
  amountConversion,
  fetchUSDTBalanceHandler,
  tetherRaisedHandler
} from "../utils/tetherhandler";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ProjectSlug = ({ connectMetamaskWallet, metaMaskAddress }) => {
  const [wbrgeBalance, setWbrgeBalance] = useState(0);
  const { width, height } = useWindowSize();
  const [showConfetti, setShowConfetti] = useState(false);
  const [runConfetti, setRunConfetti] = useState(false);
  const [usdtBalance, setUsdtBalance] = useState(0);
  const [raisedTether, setRaisedTether] = useState(0);
  const navigate = useNavigate();
  const checkTether = async () => {
    try {
      let amount = await tetherRaisedHandler();
      console.log(amount);
      setRaisedTether(parseInt(amount / 10 ** 6));
    } catch (err) {
      toast.error(err.message);
    }
  };
  const getUSDTBalabce = async () => {
    try {
      let amount = await fetchUSDTBalanceHandler({ address: metaMaskAddress });
      setUsdtBalance(amount / amountConversion(1));
    } catch (err) {
      toast.error(err.message);
    }
  };
  useEffect(() => {
    fetchWbrgeBalance().then((res) => {
      setWbrgeBalance(res);
    });
    checkTether();
  }, []);

  useEffect(() => {
    if (metaMaskAddress) getUSDTBalabce();
  }, [metaMaskAddress]);

  useEffect(() => {
    let timerId;

    if (showConfetti) {
      // Start a timer for 3 seconds
      timerId = setTimeout(() => {
        // When 3 seconds end, set showConfetti to false
        setShowConfetti(false);
      }, 3000); // 3000 milliseconds = 3 seconds
    }

    // Cleanup function to clear the timer if the component unmounts
    // or if showConfetti changes before the timer ends
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [showConfetti]);

  return (
    <div className="flex flex-col gap-8 items-center my-[10vh] md:gap-5 relative">
      <Confetti
        width={width}
        height={height}
        run={runConfetti}
        recycle={showConfetti}
        numberOfPieces={500}
      />
      <div className="w-full">
        <div className="flex items-center gap-3">
          <p className="text-white text-4xl font-bold gradient-text font-syne md:text-2xl text-center w-full">
            Exclusive Sales
          </p>
          {/* <button
            className="landing-page-hero-content--button"
            onClick={() => {
              setShowConfetti(true);
              setRunConfetti(true);
            }}>
            Confirm
          </button> */}
        </div>
        {/* <div className="flex justify-center gap-3 py-3 text-white">
          <a
            className="landing-page-hero-content--button py-2 px-2"
            href="https://twitter.com/sugarkingdomnft">
            <FaXTwitter />
          </a>
          <a
            className="landing-page-hero-content--button py-2 px-2"
            href="https://www.sugarkingdom.io/">
            <FaLink />
          </a>
        </div> */}
      </div>
      <div className="flex justify-center items-center gap-16 md:!flex-col flex-row md:gap-10 md:px-2">
        <PresaleBox
          saleType="Whitelist Sale"
          connectMetamaskWallet={connectMetamaskWallet}
          metaMaskAddress={metaMaskAddress}
          usdtBalance={usdtBalance}
          raisedTether={raisedTether}
        />
        <PresaleBox
          saleType="Presale"
          connectMetamaskWallet={connectMetamaskWallet}
          metaMaskAddress={metaMaskAddress}
          usdtBalance={usdtBalance}
          raisedTether={raisedTether}
        />
      </div>
      <button
        onClick={() => {
          navigate("/projects");
        }}
        className="underline text-white">
        Go back to Projects
      </button>
    </div>
  );
};

export default ProjectSlug;
